import React from 'react';
import { FormattedNumber } from 'react-intl';

import DataRow from 'components/Shared/DataRow/DataRow';
import FormattedDateNoTimeZone from 'components/Shared/FormattedDateNoTimeZone/FormattedDateNoTimeZone';
import Hr from 'components/Shared/Hr/Hr';
import { fontSize, colors, breakpoints } from 'styles/cp';

export interface InvoiceSummaryProps {
  invoiceNumber?: string;
  invoiceDueDate?: string;
  invoiceAmount: number;
  shouldShowInvoiceLineItems?: boolean;
  currency?: string;
  gratuityValue?: number;
  hrColor: string;
}

const InvoiceSummary: React.FC<InvoiceSummaryProps> = ({
  invoiceNumber,
  invoiceDueDate,
  invoiceAmount,
  shouldShowInvoiceLineItems,
  currency,
  gratuityValue = 0,
  hrColor,
}) => {
  return (
    <>
      {shouldShowInvoiceLineItems && (
        <span className="invoice-preview">
          <Hr
            marginTop={16}
            marginBottom={16}
            opacity={1}
            borderColor={hrColor}
            borderThickness={1}
          />
          <DataRow
            idValue="INVOICE_SUMMARY_TITLE_PREVIEW"
            defaultMessage="Invoice preview"
            fontSize="16px"
            bold
          />
        </span>
      )}

      {invoiceNumber && (
        <DataRow
          idValue={
            shouldShowInvoiceLineItems
              ? 'INVOICE_SUMMARY_INVOICE_NUMBER'
              : 'INVOICE_SUMMARY_INVOICE'
          }
          defaultMessage={shouldShowInvoiceLineItems ? 'Number' : 'Invoice'}
          value={invoiceNumber}
        />
      )}
      {invoiceDueDate && (
        <DataRow
          idValue="INVOICE_SUMMARY_DUE_DATE"
          defaultMessage="Due Date"
          value={<FormattedDateNoTimeZone value={invoiceDueDate} />}
        />
      )}
      {invoiceAmount >= 0 && !shouldShowInvoiceLineItems && (
        <DataRow
          idValue="INVOICE_SUMMARY_INVOICE_AMOUNT"
          defaultMessage="Invoice amount"
          value={<FormattedNumber value={invoiceAmount} style="currency" currency={currency} />}
        />
      )}
      {gratuityValue > 0 && (
        <DataRow
          idValue="INVOICE_SUMMARY_INVOICE_TIP"
          defaultMessage="Tip"
          value={<FormattedNumber value={gratuityValue} style="currency" currency={currency} />}
        />
      )}

      <style jsx>{`
        .invoice-preview {
          display: block;
          @media screen and (max-width: ${breakpoints.md}) {
            display: none;
          }
        }
        .label {
          font-size: ${fontSize.xs};
          color: ${colors.gray};
          font-family: AvenirNextforINTUIT-Regular;
        }
      `}</style>
    </>
  );
};

export default InvoiceSummary;
