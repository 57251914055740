import React, { Fragment } from 'react';
import { FormattedNumber } from 'react-intl';

import { fontSize, colors } from 'styles/cp';

export interface InvoiceItemProps {
  description: string;
  amount: number;
  currency?: string;
}

const InvoiceItem = ({ description, amount, currency }: InvoiceItemProps) => (
  <Fragment>
    <div className="space-between flex-row" aria-label="Invoice item description">
      <span className="label left description" title={description}>
        {description}
      </span>
      <span className="label label-data right">
        <FormattedNumber value={amount} style="currency" currency={currency} />
      </span>
    </div>

    <style jsx>{`
      .space-between {
        display: flex;
        justify-content: space-between;
      }
      .flex-row {
        flex-direction: row;
        margin-bottom: 11px;
      }
      .label {
        font-size: ${fontSize.xs};
        color: ${colors.gray};
        display: flex;
        font-family: AvenirNextforINTUIT-Regular;
      }
      .right {
        text-align: right;
        font-family: AvenirNextforINTUIT-Regular;
        color: ${colors.gray01};
      }
      .left {
        text-align: left;
        max-width: 182px;
        padding-right: 0;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .description {
        position: relative;
      }
      .description:hover {
        cursor: help;
      }
      .description::after {
        content: attr(title);
        display: inline-block;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        background-color: black;
        color: white;
        padding: 0.25rem;
        border-radius: 0.25rem;
        font-size: 0.875rem;
        white-space: pre-line;
        max-width: 200px;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }
      .description:hover::after {
        opacity: 1;
      }
    `}</style>
  </Fragment>
);

export default InvoiceItem;
