import React, { Fragment, useState } from 'react';
import { FormattedNumber } from 'react-intl';

import InvoiceItemsList from 'components/Core/Invoice/InvoiceItemsList/InvoiceItemsList';
import DataRow from 'components/Shared/DataRow/DataRow';
import ExtendBtn from 'components/Shared/ExtendBtn/ExtendBtn';
import Hr from 'components/Shared/Hr/Hr';
import { Line } from 'pages/subscription.page';
import { colors, fontSize } from 'styles/cp';

export interface InvoiceLineItemsProps {
  invoiceLineItems?: Line[];
  currency?: string;
  subTotalAmount: number;
  discountAmount: number;
  shippingAmount: number;
  invoiceTaxAmount?: number;
  hrColor: string;
}

const InvoiceLineItems = ({
  invoiceLineItems,
  currency,
  subTotalAmount,
  discountAmount,
  shippingAmount,
  invoiceTaxAmount,
  hrColor,
}: InvoiceLineItemsProps) => {
  const filteredLineItems = invoiceLineItems?.filter((lineItem) => lineItem?.item !== null);

  const [isShowFullInvoiceItems, setIsShowFullInvoiceItems] = useState(false);

  const shouldShowMoreLineItems = filteredLineItems && filteredLineItems?.length > 6;
  const firstLineItems = filteredLineItems?.slice(0, 7);
  const extraLineItems = filteredLineItems?.slice(7, filteredLineItems.length);

  return (
    <Fragment>
      <style jsx>{`
        .w-open {
          max-height: ${isShowFullInvoiceItems ? '350px' : 0};
          transition: all 0.35s ease-in-out;
          overflow: hidden;
        }
        .expanded-label-wrapper {
          display: flex;
          margin-bottom: 11px;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
        }
        .label {
          font-size: ${fontSize.xs};
          color: ${colors.gray};
          font-family: AvenirNextforINTUIT-Regular;
        }
      `}</style>
      {filteredLineItems && (
        <Hr marginTop={0} marginBottom={11} opacity={1} borderColor={hrColor} borderThickness={1} />
      )}
      {invoiceLineItems && <InvoiceItemsList lineItems={firstLineItems} currency={currency} />}
      {shouldShowMoreLineItems && isShowFullInvoiceItems && (
        <div className="w-open">
          <InvoiceItemsList lineItems={extraLineItems} currency={currency} />
        </div>
      )}
      {shouldShowMoreLineItems && (
        <div
          className="expanded-label-wrapper"
          onClick={() => setIsShowFullInvoiceItems(!isShowFullInvoiceItems)}
        >
          <span className="label left" title="expanded-label">
            {isShowFullInvoiceItems ? 'Hide' : 'Show all items'}
          </span>
          <ExtendBtn opened={isShowFullInvoiceItems} handler={() => null} margin="unset" />
        </div>
      )}
      <Hr marginTop={0} marginBottom={11} opacity={1} borderColor={hrColor} borderThickness={1} />
      <DataRow
        idValue="SUBTOTAL"
        defaultMessage="Subtotal"
        value={<FormattedNumber value={subTotalAmount} style="currency" currency={currency} />}
      />
      {!!discountAmount && discountAmount > 0 && (
        <DataRow
          idValue="DISCOUNT"
          defaultMessage="Discount"
          value={
            <FormattedNumber
              value={`-${discountAmount}` as unknown as number}
              style="currency"
              currency={currency}
            />
          }
        />
      )}
      {!!shippingAmount && shippingAmount > 0 && (
        <DataRow
          idValue="SHIPPING"
          defaultMessage="Shipping"
          value={<FormattedNumber value={shippingAmount} style="currency" currency={currency} />}
        />
      )}
      {!!invoiceTaxAmount && invoiceTaxAmount > 0 && (
        <DataRow
          idValue="INVOICE_SUMMARY_INVOICE_TAX"
          defaultMessage="Tax"
          value={<FormattedNumber value={invoiceTaxAmount} style="currency" currency={currency} />}
        />
      )}
    </Fragment>
  );
};

export default InvoiceLineItems;
