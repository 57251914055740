import React, { Fragment } from 'react';

import InvoiceItem from 'components/Core/Invoice/InvoiceItem/InvoiceItem';
import { Line, LineItems } from 'pages/subscription.page';

export interface InvoiceItemsListProps {
  lineItems?: LineItems;
  currency?: string;
}

const InvoiceItemsList = ({ lineItems, currency }: InvoiceItemsListProps) => {
  const getListOfItems = (lineItems?: LineItems) =>
    lineItems?.map((lineItem: Line, index: number) => {
      const { amount, item } = lineItem;
      let itemDescription;
      if (item && item.name) {
        itemDescription = item.name;
      } else {
        return <Fragment key={index} />;
      }
      return (
        <InvoiceItem
          description={itemDescription}
          amount={amount}
          currency={currency}
          key={index}
        />
      );
    });

  return <div>{getListOfItems(lineItems)}</div>;
};

export default InvoiceItemsList;
