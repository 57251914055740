import React, { useState } from 'react';
import { FormattedNumber, FormattedMessage } from 'react-intl';

import DataRow from 'components/Shared/DataRow/DataRow';
import InvoiceStatus from 'components/Shared/InvoiceStatus/InvoiceStatus';
import CpPopover from 'components/Shared/Popover/CpPopover';

export interface InvoiceDataRowsProps {
  currency?: string;
  isPartiallyPaid?: boolean;
  feeAmount?: number;
}

const InvoiceDataRows = ({ currency, isPartiallyPaid, feeAmount }: InvoiceDataRowsProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  return (
    <>
      {isPartiallyPaid && <InvoiceStatus idValue="INVOICE_SUMMARY_PARTIALLY_PAID" />}
      {!!feeAmount && feeAmount > 0 && (
        <span
          id="maip-tooltip-w"
          onMouseEnter={() => setIsTooltipOpen(true)}
          onMouseLeave={() => setIsTooltipOpen(false)}
        >
          <DataRow
            borderBottom="dotted"
            idValue="MAIP_ONLINE_CONVENIENCE_FEE"
            defaultMessage="Online convenience fee"
            value={<FormattedNumber value={feeAmount} style="currency" currency={currency} />}
          />
        </span>
      )}
      <CpPopover
        className="cp-tooltip-wrapper"
        innerClassName="cp-tooltip"
        placement="top-end"
        target="maip-tooltip-w"
        isOpen={isTooltipOpen}
      >
        <FormattedMessage
          id="MAIP_TOOLTIP_MSG"
          defaultMessage="This is just a fee for paying online. You can still pay offline without paying this fee."
        />
      </CpPopover>
    </>
  );
};

export default InvoiceDataRows;
